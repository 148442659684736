<template>
  <section id="card-text-alignment">
    <AgreementSearch></AgreementSearch>
    <AgreementList></AgreementList>
  </section>
</template>

<script>
import AgreementSearch from "@/views/agreement/AgreementSearch";
import AgreementList from "@/views/agreement/AgreementList";

export default {
  name: "AgreementHome",
  components: {AgreementSearch, AgreementList}
}
</script>

<style scoped>

</style>
