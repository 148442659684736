<template>
  <div>
    <b-table
        :items="_agreements"
        :fields="fields"
        striped
        responsive
    >
      <template #cell(detay_goster)="row">
        <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check"/>
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Gizle' : 'Göster' }}</span>
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col md="4" class="mb-1">
              <strong>Toplam Ürün : </strong>{{ row.item.details.length }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Toplam Başarılı : </strong>{{ row.item.details.length }}
            </b-col>
            <b-col md="4" class="mb-1">
              <strong>Toplam Başarısız : </strong>{{ row.item.details.length }}
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-table
              responsive="sm"
              :items="row.item.details"
              :fields="fields2"
          >
          </b-table>

        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge,
} from 'bootstrap-vue'
import {mapGetters} from "vuex";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
  },
  data() {
    return {
      fields2: [
        {
          key: 'id',
          label: 'Sıra No'
        },
        {
          key: 'search_tracking_number',
          label: 'Takip no'
        },
        {
          key: 'order_id',
          label: 'Sipariş No'
        },
        {
          key: 'order.store.store_name',
          label: 'Mağaza'
        },
        {
          key: 'order.status.status',
          label: 'Durum'
        },
        {
          key: 'user.name',
          label: 'Ekleyen'
        }
      ],
      fields: [
        {
          key: 'detay_goster',
          label: 'Detaylar'
        },
        {
          key: 'id',
          label: 'Sıra No'
        },
        {
          key: 'user.name',
          label: 'Ekleyen'
        },
        {
          key: 'created_at',
          label: 'Ekleme Tarihi'
        },
      ]
    }
  },
  computed: {
    ...mapGetters("agreement", ["_agreements"])
  }
}
</script>
