<template>
  <section id="search-section">
    <b-row>
      <b-col md="12">
        <b-card class="mt-1 mb-1">
          <b-overlay
              :show="show"
              rounded="sm"
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col md="10">

                </b-col>
                <b-col md="2">
                  <b-form-group label="Yönetim">
                    <b-button-group size="sm">
                      <!-- Butonlar Buraya Gelecek -->
                      <b-button variant="primary" @click="getAll">Getir</b-button>
                    </b-button-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: "AgreementSearch",
  data: () => ({
    show: false
  }),
  methods: {
    getAll() {
      this.$store.dispatch('agreement/getAll');
    }
  }
}
</script>

<style scoped>

</style>
